// react-date-picker styles
.react-datepicker {
  border-radius: 10px;
  border: 1px solid var(--neutral03);
  padding-top: 10px;
  background-color: #fff;
}
.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none;
  padding: 0 !important;
}

.react-datepicker__current-month {
  text-transform: capitalize;
}
.react-datepicker__navigation {
  top: 5px !important;
  outline: none;

  &:focus{
    outline: none;
  }
  &-icon{
    font-size: 16px;
  }
}
.react-datepicker__day-names {
  background-color: var(--neutral02) !important;
  margin-top: 15px;
  margin-bottom: -1px !important;
}
.react-datepicker__input-container {
  & > input[type="text"] {
    background: inherit;
    color: var(--neutral10) !important;
  }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--neutral10) !important;
  text-transform: uppercase;
  font-family: $lato;
  font-size: 12px;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--neutral10) !important;
  font-family: $lato;
  font-size: 15px !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled{
  color: var(--neutral06) !important;
}

//selected day
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--neutral02) !important;
  border-radius: 50%;
  color: var(--neutral10) !important;
}

.react-datepicker__day--outside-month {
  color: var(--neutral08) !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover{
  background-color: var(--neutral06) !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range{
  background-color: var(--neutral02) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-range{
  color: var(--neutral10)  !important;
  background-color: var(--primary02) !important;

  &.react-datepicker__day--range-end,
  &.react-datepicker__day--range-start,
  &.react-datepicker__day--selecting-range-start{
    color: #fff !important;
    background-color: var(--primary09) !important;
  }
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover{
  color: #fff !important;
}

//scrollbar years dropdown
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown{
  color: var(--neutral10) !important;
  background-color: #fff !important;
  border-radius: 0;

  // @include scrollbar();
}

.datepicker-large{// react-date-picker styles
  padding: 8px 16px;

  .react-datepicker {
    border-radius: 10px;
    border: none;
    display: flex;
  }
}