* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0px;

  --primary01: #f7f9ff;
  --primary02: #e7edfd;
  --primary03: #d6e1fc;
  --primary04: #c3d3fb;
  --primary05: #aec3fa;
  --primary06: #96b2f8;
  --primary07: #7a9df6;
  --primary08: #5784f4;
  --primary09: #265ff1;
  --primary10: #0d359a;
  --primary11: #0a2876;

  --secondary10: #43410f;
  --secondary09: #736f19;
  --secondary08: #928c1f;
  --secondary07: #aaa325;
  --secondary06: #beb729;
  --secondary05: #d0c82d;
  --secondary04: #e0d830;
  --secondary03: #efe634;
  --secondary02: #fdf336;
  --secondary01: #fffcc5;

  --neutral01: #f9f9fa;
  --neutral02: #ecedef;
  --neutral03: #e0e1e3;
  --neutral04: #d2d3d5;
  --neutral05: #c3c4c5;
  --neutral06: #b2b3b4;
  --neutral07: #9fa0a1;
  --neutral08: #88898a;
  --neutral09: #6c6c6d;
  --neutral10: #3f3f40;

  --coolColor01: #e9e9f1;
  --coolColor02: #cfcfe2;
  --coolColor03: #adadcd;
  --coolColor04: #7a7aae;
  --coolColor05: #5d5d98;
  --coolColor06: #515185;
  --coolColor07: #404064;
  --coolColor08: #2c2c44;
  --coolColor09: #202031;
  --coolColor10: #14141f;

  --critial04: #bf411e;
  --critial03: #f15327;
  --critial02: #f9b3a0;
  --critial01: #fcd9d0;

  --warning04: #f3c03f;
  --warning03: #f9de9d;
  --warning02: #fbedc7;
  --warning01: #fef9ed;

  --success04: #1ebd41;
  --success03: #39df5d;
  --success02: #6ff68c;
  --success01: #c2fbce;

  --info04: #0d4554;
  --info03: #1d95b6;
  --info02: #25c2ed;
  --info01: #f1fbfe;

  --yellow: #f3be3f;
  --yellow2: #fefaf1;
}
