@mixin text ($fontSize : 16px, $fontWeight : 400, $color : var(--neutral10)) {
  font-size: $fontSize !important;
  font-weight: $fontWeight !important;
  font-family: $lato !important;
  color: $color !important;
  margin: 0;
}


.text-small{
  @include text(14px, 400, var(--neutral10));
}

.text-small-bold{
  @include text(14px, 700, var(--neutral10));
}

.text-large-bold{
  @include text(20px, 700, var(--neutral10));
}

.text-regular{
  @include text(16px, 400, var(--neutral10));
}

.text-gray{
  @include text(16px, 400, var(--neutral08));
}

.link{
  @include text(14px, 400, #0f4eee);
  background-color: transparent;
  outline: none;

  &:hover{
    text-decoration: underline;
    outline: none;
  }

  &:focus{
    outline: none;
  }
}