.dashboard {
  font-size: 2rem;
}

.content-header-card {
  padding: 26px 24px 0px 24px;

  .title_1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1f2a37;
  }

  .title_2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: right;
    color: #1f2a37;
  }
}

.content-body-card {
  .list-detail {
    .content-detail {
      .title_1 {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      .row {
        margin-top: 16px;

        div:nth-child(1) {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }

        div:nth-child(2) {
          text-align: right;

          span:nth-child(1) {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }

          span:nth-child(2) {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.percentageBox {
  background: #4DFF8B;
  border-radius: 15px;
  color: #467E5A;
  font-size: 10px;
  height: 16px;
  padding: 0 5px;
  margin-top: 2px;
  line-height: 16px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.color-link{
  color: #265ff1 !important;
}