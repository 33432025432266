@mixin border-radius($border) {
  -webkit-border-radius: $border;
  -moz-border-radius: $border;
  -ms-border-radius: $border;
  -o-border-radius: $border;
  border-radius: $border;
}
//Breakpoints
$xsmall-screen: 320px;
$small-screen: 480px;
$medium-screen: 768px;
$xmedium-screen: 1024px;
$large-screen: 1200px;
$xlarge-screen: 1360px;

//Width column
@function col($columns, $total) {
  @return (100% / $total) * $columns;
}

@mixin media_min_width($pixel) {
  @media only screen and (min-width: $pixel) {
    @content;
  }
}

@mixin media_max_width($pixel) {
  @media only screen and (max-width: $pixel) {
    @content;
  }
}

@mixin media_between($lowPx, $maxPx) {
  @media only screen and (min-width: $lowPx) and (max-width: $maxPx) {
    @content;
  }
}

@mixin media($types...) {
  @each $type in $types {
    //only mobile or mobile down
    @if $type==mobile {
      @media only screen and (max-width: $medium-screen) {
        @content;
      }
    }

    @if $type==mobile-tablet {
      @media only screen and (min-width: $small-screen) {
        @content;
      }
    }

    //tableb up
    @if $type==tablet {
      @media only screen and (min-width: $medium-screen) and (max-width: $large-screen) {
        @content;
      }
    }

    //desktopmini up
    @if $type==desktopmini {
      @media only screen and (min-width: $large-screen) {
        @content;
      }
    }

    //desktop up
    @if $type==desktop {
      @media only screen and (min-width: $xlarge-screen) {
        @content;
      }
    }
  }
}

/* Center and max-width the content */
body {
  //background-image: url('../../assets/images/backgrounds/shapes.svg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  font-family: $lato;
  color: var(--neutral10);
}
.container {
  width: 90%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;

  @include media_min_width($medium-screen) {
    width: 95%;
    max-width: 1184px;
  }
  @include media(mobile) {
    padding: 0 32px;
  }
}

$spaceamounts: (
  0,
  4,
  6,
  8,
  12,
  16,
  18,
  20,
  24,
  26,
  28,
  32,
  36,
  40,
  44,
  48,
  80,
  120,
  160
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    // .p#{str-slice($side, 0, 1)}-#{$space} {
    //   padding-#{$side}: #{$space}px !important;
    // }
  }

  .mx-#{$space} {
    margin-left: #{$space}px !important;
    margin-right: #{$space}px !important;
  }
  .px-#{$space} {
    padding-left: #{$space}px !important;
    padding-right: #{$space}px !important;
  }
  .my-#{$space} {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }
  .py-#{$space} {
    padding-top: #{$space}px !important;
    padding-bottom: #{$space}px !important;
  }
}

.v-line{
  margin: 0;
  border-right: 1px solid var(--neutral03);
  height: 100%;
}

.rotate-270-deg{
  transform: rotate(270deg);
}