.modal-date{
  max-width: max-content !important;

  .modal-date__dates{
    display: flex;
    border-bottom: 1px solid var(--neutral02);

    .modal-date__buttons{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border-right: 1px solid var(--neutral02);

      button{
        background-color: #fff;
        width: 100%;
        padding: 6px 12px;
        text-align: start;
        outline: none;
        @include text(15px, 400, var(--neutral10));

        &:hover{
          background-color: var(--neutral01);
          outline: none;
        }
        &:focus{
          outline: none;
        }
        &.active{
          background-color: var(--neutral02);
        }
      }
    }
  }

  .modal-date__footer{
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr auto;
    padding: 1rem 2rem;

    .selectors{
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;
    }
    .buttons{
      display: flex;
      align-items: end;
      gap: 16px;
    }
  }
}
// modal mobile
.sidebar-dates{
  .buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    button{
      background-color: #fff;
      width: 100%;
      padding: 10px;
      text-align: start;
      outline: none;
      @include text(16px, 400, var(--neutral10));

      &:hover{
        background-color: var(--neutral01);
        outline: none;
      }
      &:focus{
        outline: none;
      }
      &.active{
        background-color: var(--neutral02);
      }
    }
  }
}